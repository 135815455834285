import React from "react";
import { Stack } from "@mui/material";
import ProfileAvatar from "./Components/ProfileAvatar";
import profilepic from "../../Assets/profilepic.jpg";
const ChatProfiles = () => {
  const profileAvatars = [
    { imgUrl: profilepic, action: true },
    { imgUrl: profilepic, action: false },
    { imgUrl: profilepic, action: false },
    { imgUrl: profilepic, action: true },
    { imgUrl: profilepic, action: false },
    { imgUrl: profilepic, action: false },
  ];
  return (
    <Stack>
      {profileAvatars.map((data, index) => (
        <ProfileAvatar
          imgUrl={data.imgUrl}
          online={data.action}
          key={`##${index}`}
        />
      ))}
    </Stack>
  );
};

export default ChatProfiles;
