import Box from "@mui/material/Box";
import { Navigate, Route, Routes } from "react-router-dom";
import MainSideBar from "../components/dashboard/MainSideBar";
import Loadable from "./Loadable";
import { lazy } from "react";
import TokenAuth from "../components/Redirect/TokenAuth";
import useUser from "../store/userStore";
import Profile from "../components/User/Profile";
import NavBar from "../components/dashboard/NavBar";
import GlobalDashBoard from "../globaldashboard";
import {
  useOpenChatStore,
  usePinChatStore,
  useTeamChatStore,
} from "../TeamChat/store/teamChatStore";
import TeamChat from "../TeamChat";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const PrivateRoute = () => {
  const { teamChat } = useTeamChatStore();
  const { pinChat } = usePinChatStore();
  const { openChat } = useOpenChatStore();
  const Webservices = Loadable(lazy(() => import("../webservices")));
  const Peoplesnow = Loadable(lazy(() => import("../peoplesnow")));
  const Ndemail = Loadable(lazy(() => import("../ndemail/Mail")));
  const MarketingStudio = Loadable(
    lazy(() => import("../MarketingStudio/index"))
  );
  const CRM = Loadable(lazy(() => import("../crm")));
  const SpotNow = Loadable(lazy(() => import("../spotnow/Index")));
  const NdeDrive = Loadable(lazy(() => import("../ndedrive/index")));
  const ChatNow = Loadable(lazy(() => import("../chatnow/index")));
  const Meet = Loadable(lazy(() => import("../ndeMeet/index")));
  const Docs = Loadable(lazy(() => import("../ndeDocs/index")));

  const { user, getToken } = useUser();

  if (!user || !getToken()) {
    return <Navigate to="/login" />;
  }
  return (
    <>
      <Box height={"100vh"} width={"100vw"}>
        <Box height={"48px"} width={"100vw"}>
          <NavBar />
        </Box>

        <Box
          height={"100vh"}
          width={"100vw"}
          paddingLeft={"65px"}
          paddingRight={
            teamChat
              ? openChat && pinChat
                ? "22vw"
                : !openChat
                ? "56px"
                : ""
              : "0px"
          }
        >
          <MainSideBar />

          <Routes>
            <Route path="/dashboard/*" element={<GlobalDashBoard />} />
            <Route path="*" element={<Navigate to={"/dashboard"} />} />
            <Route path="/webservices/*" element={<Webservices />} />
            <Route path="peoplesnow/*" element={<Peoplesnow />} />
            <Route path="/markettingstudio/*" element={<MarketingStudio />} />
            <Route path="/token-auth/:jwt" element={<TokenAuth />} />
            <Route path="spotnow/*" element={<SpotNow />} />
            <Route path="/myaccount/*" element={<Profile />} />

            <Route path="/mail/*" element={<Ndemail />} />
            <Route path="/drive/*" element={<NdeDrive />} />
            <Route path="/crm/*" element={<CRM />} />
            <Route path="/chatnow/*" element={<ChatNow />} />
            <Route path="/meet/*" element={<Meet />} />
            <Route path="/docs/*" element={<Docs />} />
          </Routes>
        </Box>
      </Box>
      {teamChat && (
        <Box position={"absolute"} top={"48px"} right={"0px"}>
          <TeamChat />
        </Box>
      )}
    </>
  );
};

export default PrivateRoute;
