import { Grid, LinearProgress, Typography } from "@mui/material";
import React from "react";
import smiley from "../../../../Images/smiley.png";
import view from "./Overview.module.scss";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Perfomance = () => {
  return (
    <div className={view.performance}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
              Email Performance
            </Typography>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                marginBottom: "7px",
                margin: "10px 10px 7px",
              }}
            >
              <img
                src={smiley}
                alt="smiley"
                style={{
                  height: "25px",
                  width: "25px",
                }}
              />
              <Typography
                sx={{ fontSize: "20px", fontWeight: 600, color: "#6CCB4B" }}
              >
                Super
              </Typography>
            </div>
            <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
              100% of warmup emails landed in inbox
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div>
            <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
              Outbount Status
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                margin: "10px 0 8px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <CheckCircleIcon
                  fontSize="small"
                  sx={{ color: "#757AE9", fontSize: "18px" }}
                />

                <Typography
                  sx={{ fontSize: "15px", fontWeight: 400, color: "#757AE9" }}
                >
                  Warmup
                </Typography>
              </div>
              <div>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  Outbound
                </Typography>
              </div>
            </div>
            <LinearProgress
              variant="determinate"
              value={10}
              sx={{
                height: "13px",
                marginBottom: "10px",
                color: "#757AE9",
                bgcolor: "#E5E5E5",
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                Enabled on Friday, Sep 13, 2024
              </Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                Use for Outbound
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Perfomance;
