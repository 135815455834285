import {create} from 'zustand';
import { persist } from 'zustand/middleware';

const empHistoryStore = create((
    (set) => ({
      empHistory1: "",
      setEmpHistory1: (newValue) => set({ empHistory1: newValue }),
    })
  ));

  const addProspectWindow = create((
    (set) => ({
      open: false,
      setOpen: (newValue) => set({ open: newValue }),
    })
  ));

  const emailProvider = create((
    (set) => ({
      emailProviderValue: '',
      setEmailProviderValue: (newValue) => set({ emailProviderValue: newValue }),
    })
  ));

  const emailProviderGlobal = create((
    (set) => ({
      emailProviderValue: '',
      setEmailProviderValue: (newValue) => set({ emailProviderValue: newValue }),
    })
  ));

  const smtpData = create((
    (set) => ({
      smtpValues: '',
      setSmtpValues: (newValue) => set({ smtpValues: newValue }),
    })
  ));

  const smtpDataGlobal = create((
    (set) => ({
      smtpValues: '',
      setSmtpValues: (newValue) => set({ smtpValues: newValue }),
    })
  ));

  const imapData = create((
    (set) => ({
      imapValues: '',
      setImapValues: (newValue) => set({ imapValues: newValue }),
    })
  ));

  const imapDataGlobal = create((
    (set) => ({
      imapValues: '',
      setImapValues: (newValue) => set({ imapValues: newValue }),
    })
  ));

  const smtpFields = create((
    (set) => ({
      values: [],
      setValues: (newValue) => set({ values: newValue }),
    })
  ));

  const smtpFieldsGlobal = create((
    (set) => ({
      values: [],
      setValues: (newValue) => set({ values: newValue }),
    })
  ));

  const emailAccountAdd = create((
    (set) => ({
      status: false,
      setStatus: (newValue) => set({ status: newValue }),
    })
  ));


  const ramUpData = create((
    (set) => ({
      value: [],
      setValue: (newValue) => set({ value: newValue }),
    })
  ));

  const stepDataValues = create((
    (set) => ({
      stepValue: [],
      setStepValue: (newValue) => set({ stepValue: newValue }),
    })
  ));

  const stepId = create((
    (set) => ({
      stepid: null,
      setStepId: (newValue) => set({ stepid: newValue }),
    })
  ));

  const fieldMappingFun = create(
    persist(
    (set) => ({
      fieldMapping: {},
      setFieldMapping: (mapping) => set((state) => ({
        fieldMapping: { ...state.fieldMapping, ...mapping },
      })),
    }),
    {
      name: 'field-mapping-storage',
      getStorage: () => localStorage,
    }
  ));

  const snackbarStore = create((
    (set) => ({
      snackbarValue: {open:false,severity:"",message:""},
      setSnackbarValue: (newValue) => set({ snackbarValue: newValue }),
    })
  ));

  const csvUpload = create((
    (set) => ({
      uploadedFile: null,
      setUploadedFile: (newValue) => set({ uploadedFile: newValue }),
    })
  ));

  const saveStepTrigger = create((
    (set) => ({
      trigger: false,
      setTrigger: (newValue) => set({ trigger: newValue }),
    })
  ));

  const newCampaignSubject = create((set) => ({
    emailSubject: {
      subject: '',
      mergeTag: '',
    },
    setEmailSubject: (key, value) =>
      set((state) => ({
        emailSubject: { ...state.emailSubject, [key]: value }
      })),
  }));

  const setNewVariantidStore = create((
    (set) => ({
      newVariantid: '',
      setNewVariantid: (newValue) => set({ newVariantid: newValue }),
    })
  ));

  const setNewSequenceIdStore = create((
    (set) => ({
      newSequenceId: '',
      setNewSequenceId: (newValue) => set({ newSequenceId: newValue }),
    })
  ));

  const sequenceNameStore = create((
    (set) => ({
      sequenceName: '',
      setSequenceName: (newValue) => set({ sequenceName: newValue }),
    })
  ));

  const stepStore = create((
    (set) => ({
      stepId: '',
      setstepId: (newValue) => set({ stepId: newValue }),
    })
  ));

  const useCsvStore = create(
    persist(
      (set) => ({
        csvData: [],
        setCsvData: (data) => set(() => ({ csvData: data })),
        clearCsvData: () => set(() => ({ csvData: [] })),
      }),
      {
        name: 'csv-storage', 
        getStorage: () => localStorage, 
      }
    )
  );
  

  export {
    empHistoryStore,
    addProspectWindow,
    emailProvider,
    smtpData,
    imapData,
    smtpFields,
    emailAccountAdd,
    ramUpData,
    stepDataValues,
    stepId,
    fieldMappingFun,
    snackbarStore,
    emailProviderGlobal,
    smtpDataGlobal,
    smtpFieldsGlobal,
    imapDataGlobal,
    csvUpload,
    saveStepTrigger,
    newCampaignSubject,
    setNewVariantidStore,
    setNewSequenceIdStore,
    sequenceNameStore,
    stepStore,
    useCsvStore
  }