import { Box, IconButton, Modal, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import Connections from "./Connections";
import GoogleImg from "../../../../Images/google-logo.png";
import axios from "axios";

const AddAccountTab = ({ openSmtp, setOpenSmtp, data }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleClose = () => {
    setOpenSmtp(false);
    setActiveTab(0);
  };
  const handleGoogleSignIn = async () => {
    try {
      const response = await axios.get (`${process.env.REACT_APP_MAILS_NOW_BASEURL}/auth/google-auth`); 
      const redirectUrl = response.data;
      window.location.href = redirectUrl;
    } catch (error) {
      console.error("Error during API call: ", error);
    }
  };
  return (
    <Modal
      open={openSmtp}
      onClose={handleClose}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        },
      }}
    >
      <Box
        sx={{
          borderRadius: "4px",
          bgcolor: "background.paper",
          position: "absolute",
          height: "85%",
          width: "70%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "15px 20px",
            borderBottom: "1px solid var(--light-border-color)",
            height: "55px",
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleChange}
            aria-label="MailsNow Tabs"
          >
            <Tab
              sx={{ textTransform: "none" }}
              label="Connect SMTP/IMAP account"
            />
            <Tab sx={{ textTransform: "none" }} label="Google Accounts" />
          </Tabs>

          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseOutlined />
            </IconButton>
          </Box>
        </Box>

        <Box
          sx={{
            overflowY: "auto",
            height: "calc(100% - 55px)",
          }}
        >
          {activeTab === 0 && (
            <Connections
              data={data}
              setOpenSmtp={setOpenSmtp}
              openSmtp={openSmtp}
            />
          )}
          {activeTab === 1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  border: "1px solid var(--light-border-color)",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "50px",
                  justifyContent: "center",
                  width: "250px",
                  cursor: "pointer",
                  ":hover": {
                    backgroundColor: "#EFF6FF",
                  },
                }}
                onClick={handleGoogleSignIn}
              >
                <img
                  src={GoogleImg}
                  alt="google icon"
                  style={{ width: "50px", height: "50px" }}
                />
                <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
                  Sign in with Google
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default AddAccountTab;
