import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import CustomTextField from "./CustomTextField";
import Footer from "./Footer";
import axios from "axios";
import { Country } from "country-state-city";
import CustomSnackBar from "../../SnackBar/CustomSnackBar";
import { useQuery } from "@tanstack/react-query";

const SingUpForm = () => {
  const [snackbaropen, setSnackbaropen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(3, "Minnimum 3 char")
      .max(50, "max 50 char")
      .required("Please enter first_name"),
    last_name: Yup.string()
      .min(1, "Minnimum 1 char")
      .max(50, "max 50 char")
      .required("Please enter last_name"),
    companyName: Yup.string()
      .min(3, "Minnimum 3 char")
      .max(50, "max 50 char")
      .required("Please enter company_name"),
    password: Yup.string()
      .min(3, "Minnimum 8 char")
      .max(50, "max 50 char")
      .required("Please enter password"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Please enter Email"),
    phone_number: Yup.number().required("Please Enter The phonenumber"),

    address: Yup.string()
      .min(3, "Minnimum 3 char")
      .max(100, "max 100 char")
      .required("Please enter Address"),
    city: Yup.string().required("Please Select The city"),
    pincode: Yup.number().required("Please Select The pincode"),
    country: Yup.string().required("Please Select The country"),
    state: Yup.string().required("Please Select The state"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      city: "",
      pincode: "",
      state: "",
      country: "",
      gstin: "",
      companyName: "",
      customerType: "customer",
      password: "",
      address: "",
    },

    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      console.log("mmmmkkkk",values)
      setSubmitting(true);
      const params = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone_number: values.phone_number,
        city: values.city,
        pincode: values.pincode,
        state: values.pincode,
        country: values.country,
        gstin: values.gstin,
        companyName: values.companyName,
        password: values.password,
        address: values.address,
      };

      axios
        .post(`${process.env.REACT_APP_WEBSERVICES_PRODUCTION_URL}/client/signup`, params)
        .then(function (response) {
          localStorage.setItem("auth", JSON.stringify(response.data.data));
          formik.resetForm();
          window.location.href = "/login";
        })

        .catch((error) => {
          setSubmitting(false);
        
          if (error?.response?.data?.error?.message)
            setSnackbaropen({
              open: true,
              message: error.response.data.error.message,
              severity: "error",
            });
          else {
          }
        });
    },
  });

  const handlePhoneNumberChange = (event) => {
    const formattedPhoneNumber = event;
    formik.handleChange({
      target: {
        name: "phone_number",
        value: formattedPhoneNumber,
      },
    });
  };

  // function ip2Location() {
  //   return axios
  //     .get("https://ipapi.co/json/")
  //     .then((response) => {
  //       let data = response.data;
  //       //formik.setFieldValue("country", data?.country?.isoCode);
  //       formik.setFieldValue("state", data.region);
  //       formik.setFieldValue("city", data.city);
  //       formik.setFieldValue("pincode", data.postal);
  //       return data;
  //     })
  //     .catch((error) => {
  //       return error;
  //     });
  // }

  // useQuery({
  //   queryKey: ["ip2Location"],
  //   queryFn: ip2Location,
  // });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} sx={{ padding: "5px 12px" }}>
          <CustomTextField
            fieldVal={formik.values.first_name}
            handleChange={formik.handleChange}
            err={formik.touched.first_name && !!formik.errors.first_name}
            helper={formik.touched.first_name && formik.errors.first_name}
            fieldName={"first_name"}
            cellName={"First name*"}
            isRequired={true}
            isMultiline={false}
            required={true}
          />
          <CustomTextField
            fieldVal={formik.values.last_name}
            handleChange={formik.handleChange}
            err={formik.touched.last_name && !!formik.errors.last_name}
            helper={formik.touched.last_name && formik.errors.last_name}
            fieldName={"last_name"}
            cellName={"Last name*"}
            isMultiline={false}
            required={true}

          />

          <CustomTextField
            fieldVal={formik.values.phone_number}
            handleChange={handlePhoneNumberChange}
            err={formik.touched.phone_number && !!formik.errors.phone_number}
            helper={formik.touched.phone_number && formik.errors.phone_number}
            fieldName={"phone_number"}
            cellName={"Phone Number*"}
            isMultiline={true}
            required={true}

          />

          <CustomTextField
            fieldVal={formik.values.email}
            handleChange={formik.handleChange}
            err={formik.touched.email && !!formik.errors.email}
            helper={formik.touched.email && formik.errors.email}
            fieldName={"email"}
            cellName={"Email*"}
            required={true}
          />
          <CustomTextField
            fieldVal={formik.values.password}
            handleChange={formik.handleChange}
            err={formik.touched.password && !!formik.errors.password}
            helper={formik.touched.password && formik.errors.password}
            fieldName={"password"}
            cellName={"Password*"}
            required={true}
          />
          <CustomTextField
            fieldVal={formik.values.address}
            handleChange={formik.handleChange}
            err={formik.touched.address && !!formik.errors.address}
            helper={formik.touched.address && formik.errors.address}
            fieldName={"address"}
            cellName={"Address*"}
            required={true}
          />
          <CustomTextField
            fieldVal={formik.values.companyName}
            handleChange={formik.handleChange}
            err={formik.touched.companyName && !!formik.errors.companyName}
            helper={formik.touched.companyName && formik.errors.companyName}
            fieldName={"companyName"}
            cellName={"Company Name*"}
            required={true}
          />
          <CustomTextField
            fieldVal={formik.values.gstin}
            handleChange={formik.handleChange}
            err={formik.touched.gstin && !!formik.errors.gstin}
            helper={formik.touched.gstin && formik.errors.gstin}
            fieldName={"gstin"}
            cellName={"Gst No"}
            isMultiline={false}
          />
          <CustomTextField
            fieldVal={formik.values.country}
            handleChange={formik.handleChange}
            err={formik.touched.country && !!formik.errors.country}
            helper={formik.touched.country && formik.errors.country}
            fieldName={"country"}
            cellName={"Country*"}
            isMultiline={false}
            isSelect={true}
            array={Country.getAllCountries()}
            valueParam={"name"}
            keyParam={"isoCode*"}
            required={true}

          />
          <CustomTextField
            fieldVal={formik.values.state}
            handleChange={formik.handleChange}
            err={formik.touched.state && !!formik.errors.state}
            helper={formik.touched.state && formik.errors.state}
            fieldName={"state"}
            cellName={"State*"}
            isMultiline={false}
            required={true}

          />
          <CustomTextField
            fieldVal={formik.values.city}
            handleChange={formik.handleChange}
            err={formik.touched.city && !!formik.errors.city}
            helper={formik.touched.city && formik.errors.city}
            fieldName={"city"}
            cellName={"City*"}
            isMultiline={false}
            required={true}

          />
          <CustomTextField
            fieldVal={formik.values.pincode}
            handleChange={formik.handleChange}
            err={formik.touched.pincode && !!formik.errors.pincode}
            helper={formik.touched.pincode && formik.errors.pincode}
            fieldName={"pincode"}
            cellName={"Pincode*"}
            isMultiline={false}
            required={true}

          />
        </Grid>

        <Footer
          loading={formik.isSubmitting}
          handleSubmit={formik.handleSubmit}
        />
      </form>
      <CustomSnackBar
        snackbaropen={snackbaropen}
        setSnackbaropen={setSnackbaropen}
        position="right"
      />
    </>
  );
};

export default SingUpForm;
