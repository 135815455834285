import React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Styles from "../Style/SignUpHeader.module.css";
const StyledTypo = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 500,
}));
const CustomTextField = ({
  fieldVal,
  handleChange,
  err,
  helper,
  cellName,
  fieldName,
  isMultiline,
  isSelect,
  array,
  keyParam,
  valueParam,
  required
}) => {
  console.log("bnnnnn",array)
  return (
    <Grid item xs={12} sm={6} alignItems="center" justifyContent=" center">
      <div>
        <div style={{ display: "flex" }}>
          <StyledTypo> {cellName}</StyledTypo>
        </div>
        <div>
          {isMultiline ? (
            <>
              <PhoneInput
                style={{
                  width: "100%",
                  fontSize: "1rem !important",
                  fontWeight: 400,
                  border: helper ? "1px solid #d32f2f" : "",
                  borderRadius: "5px",
                }}
                enableAreaCodes={true}
                placeholder="Enter phone number"
                value={fieldVal}
                country={"in"}
                name={fieldName}
                onChange={handleChange}
                error={err}
                helperText={helper}
              />
              <div className={Styles.phoneText}>{helper}</div>
            </>
          ) : (
            <TextField
              name={fieldName}
              value={fieldVal}
              onChange={handleChange}
              error={err}
              helperText={helper}
              size="small"
              id="standard-basic"
              fullWidth
              select={isSelect}
              SelectProps={{
                MenuProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
              required={required}
            >
              {isSelect &&
                (array ?? []).map((item) => (
                  <MenuItem
                    key={item[keyParam] || item}
                    value={item.isoCode || item}
                  >
                    {item[valueParam] || item}
                  </MenuItem>
                ))}
            </TextField>
          )}
        </div>
      </div>
    </Grid>
  );
};

export default CustomTextField;
