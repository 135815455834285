import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
const PieGraph = ({ data }) => {
  return (
    <ResponsiveContainer width={300} height={250}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={40}
          outerRadius={60}
          fill="#8884d8"
          paddingAngle={3}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieGraph;
