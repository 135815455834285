import React from "react";
import { Box, Avatar, Typography } from "@mui/material";
import { formateHour } from "../../../../CustomHooks/formateDates";
import HoverDropDown from "./HoverDropDown";

const ReceivedMsg = ({
  data,
  hoverId,
  handleOpen,
  handleClose,
  open,
  anchorEl,
  setHoverId,
  messageOptions,
  isGroupChat,
}) => {
  console.log("uploadeddata",hoverId === data._id);

  return (
    <Box
      display="flex"
      paddingTop={2}
      onMouseEnter={() => setHoverId(data._id)}
      onMouseLeave={() => setHoverId("")}
    >
      <Box paddingLeft="15px" paddingRight="5px">
        <Avatar
          alt="User Avatar"
          src="/static/images/avatar/1.jpg"
          sx={{
            height: "27px",
            width: "27px",
          }}
        />
      </Box>
      <Box
        height="auto"
        sx={{ background: "#000AFF" }}
        width="60%"
        p={1}
        border="1px solid rgba(0, 0, 0, 0.1)"
        borderRadius={1}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            {isGroupChat && (
              <Typography
                color="#e1f0ff"
                fontWeight={500}
                fontSize={11}
                paddingBottom={1}
              >
                {data?.sender?.first_name} {data?.sender?.last_name}
              </Typography>
            )}

            <Typography color="#ffff" fontWeight={500} fontSize={11}>
              {data.content}
            </Typography>
          </Box>

          {hoverId === data._id && !data.is_deleted && (
            <Box>
              <HoverDropDown
                open={open}
                handleClose={handleClose}
                handleOpen={handleOpen}
                anchorEl={anchorEl}
                messageOptions={messageOptions}
                messageId={data.message_id}
                conversationId={data.conversation_id}
                data={data}
              />
            </Box>
          )}
        </Box>
        <Box>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Typography color="#D9D9D9" fontWeight={500} fontSize={10}>
              {formateHour(data?.time)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReceivedMsg;
