import { Typography } from "@mui/material";
import React from "react";
import view from "./Overview.module.scss";
import BarGraph from "./BarGraph";

const BarChart = () => {
  return (
    <div className={view.performance}>
      <div>
        <Typography
          sx={{ fontSize: "18px", fontWeight: 600, marginBottom: "20px" }}
        >
          Warmup email sent
        </Typography>
        <BarGraph />
      </div>
    </div>
  );
};

export default BarChart;
