import React from "react";
import { IconButton, MenuItem, Menu } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const HoverDropDown = ({
  open,
  handleOpen,
  anchorEl,
  messageOptions,
  handleClose,
  messageId,
  conversationId,

}) => {


  return (
    <>
      <IconButton
        onClick={(e) => handleOpen(e)}
        id="basic-button"
        sx={{ padding: 0 }}
      >
        <ExpandMoreIcon style={{ fontSize: "16px" }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        {messageOptions.map((data) => (
          <MenuItem
            key={data.id}
            onClick={() => data.actionFunc(conversationId, messageId)}
          >
            {data.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default HoverDropDown;
