import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  Legend,
} from "recharts";

const data = [
  {
    name: "06 Sep",
    sent: 0,
    replied: 1.5,
    savedFromSpam: 0,
  },
  {
    name: "07 Sep",
    sent: 0,
    replied: 0,
    savedFromSpam: 0,
  },
  {
    name: "08 Sep",
    sent: 0,
    replied: 0,
    savedFromSpam: 0,
  },
  {
    name: "09 Sep",
    sent: 0.5,
    replied: 0,
    savedFromSpam: 0,
  },
  {
    name: "10 Sep",
    sent: 0,
    replied: 0,
    savedFromSpam: 1,
  },
];

const BarGraph = () => {
  return (
    <div style={{ height: "270px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 10,
            left: 5,
            bottom: 15,
          }}
          style={{ fontSize: "11px" }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="name" fontSize={10}>
            <Label fontSize={12} offset={0} position="bottom" />
          </XAxis>
          <YAxis
            fontSize={10}
            axisLine={false}
            label={{
              angle: -90,
              position: "Center",
              fontSize: "12px",
            }}
          />
          <Legend fontSize={10} />

          {/* Stacked Bars */}
          <Bar
            dataKey="sent"
            name="Sent"
            stackId="a"
            fill="#3792FE"
            isAnimationActive={true}
            animationDuration={1000}
            barSize={35}
          />
          <Bar
            dataKey="replied"
            name="Replied"
            stackId="a"
            fill="#FE37C6"
            isAnimationActive={true}
            animationDuration={1000}
            barSize={35}
          />
          <Bar
            dataKey="savedFromSpam"
            name="Saved from spam"
            stackId="a"
            fill="#CB4B4B"
            isAnimationActive={true}
            animationDuration={1000}
            barSize={35}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarGraph;
