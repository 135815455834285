import React from "react";
import view from "./Overview.module.scss";
import Summary from "./Summary";
import Perfomance from "./Perfomance";
import BarChart from "./Compare";
import Piechart from "./Piechart";

const Overview = () => {
  return (
    <div>
      <div className={view.Summary}>
        <Summary />
      </div>
      <div>
        <Perfomance />
      </div>
      <div>
        <Piechart />
      </div>
      <div>
        <BarChart />
      </div>
    </div>
  );
};

export default Overview;
