import { Box, Tab } from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import FollowUp from "./element/FollowUp";
import EmailPreview from "./element/EmailPreview";
import ContentGuide from "./element/ContentGuide";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ViewQuiltOutlinedIcon from "@mui/icons-material/ViewQuiltOutlined";

const style = {
  minHeight: "36px",
  padding: "6px 14px",
  height: "40px",
  textTransform: "none",
};
const EmailGuide = ({ finalPreview }) => {
  const [activeTab, setActiveTab] = useState("Content Guide");

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  useEffect(() => {
    if (finalPreview === true) {
      setActiveTab("Email Preview");
    } else {
      setActiveTab("Content Guide");
    }
  }, [finalPreview]);

  return (
    <Box
      sx={{
        borderLeft: "1px solid var(--light-border-color)",
        height: "100%",
      }}
    >
      {finalPreview === true ? (
        <Tabs
          value={activeTab}
          onChange={handleChange}
          variant="scrollable"
          // scrollButtons
          aria-label="visible arrows tabs example"
          sx={{
            minHeight: "40px",
            borderBottom: "1px solid var(--light-border-color)",
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
        >
          <Tab
            sx={style}
            icon={<ViewQuiltOutlinedIcon sx={{ fontSize: "20px" }} />}
            label="Email Preview"
            value="Email Preview"
            iconPosition="start"
          />
        </Tabs>
      ) : (
        <Tabs
          value={activeTab}
          onChange={handleChange}
          variant="scrollable"
          // scrollButtons
          aria-label="visible arrows tabs example"
          sx={{
            minHeight: "40px",
            borderBottom: "1px solid var(--light-border-color)",
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
        >
          <Tab
            sx={style}
            icon={<DescriptionOutlinedIcon sx={{ fontSize: "20px" }} />}
            label="Content Guide"
            value="Content Guide"
            iconPosition="start"
          />
          {/* <Tab
            sx={style}
            icon={<MailOutlinedIcon sx={{ fontSize: "20px" }} />}
            label="Email follow up"
            value="Email follow up"
            iconPosition="start"
          /> */}
        </Tabs>
      )}

      <Box sx={{ height: "calc(100vh - 120px)" }}>
        {activeTab === "Content Guide" && <ContentGuide />}
        {activeTab === "Email Preview" && <EmailPreview />}
        {activeTab === "Email follow up" && <FollowUp />}
      </Box>
    </Box>
  );
};

export default EmailGuide;
