import { Typography } from "@mui/material";
import React from "react";
import view from "./Overview.module.scss";
import PieLegend from "./PieLegend";
import PieGraph from "./PieGraph";

const Piechart = () => {
  const data = [
    { name: "Landed from inbox", value: 100, fill: "#6CCB4B", count: 1 },
    { name: "Saved from spam", value: 0, fill: "#CB4B4B", count: 0 },
  ];
  return (
    <div className={view.performance}>
      <Typography
        sx={{ fontSize: "18px", fontWeight: 600, marginBottom: "20px" }}
      >
        Inbox vs Spam
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "200px",
        }}
      >
        <div>
          <PieGraph data={data} />
        </div>
        <div>
          <PieLegend data={data} />
        </div>
      </div>
    </div>
  );
};

export default Piechart;
