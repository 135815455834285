import React from "react";
import view from "./Overview.module.scss";
import { Grid, Typography } from "@mui/material";

const GridItem = ({ value, label, color }) => (
  <>
    <Grid item xs={12} md={6} lg={3}>
      <div className={view.gridContainer}>
        <Typography
          sx={{
            fontSize: "32px",
            fontWeight: 500,
            textAlign: "center",
            lineHeight: "42px",
            color,
          }}
        >
          {value}
        </Typography>
        <Typography
          sx={{
            color: "#282b42",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "18px",
          }}
        >
          {label}
        </Typography>
      </div>
    </Grid>
  </>
);

const Summary = () => {
  const data = [
    { value: 3, label: "Warmup emails sent", color: "#757AE9" },
    { value: 3, label: "Landed in inbox", color: "#6CCB4B" },
    { value: 0, label: "Saved from spam", color: "#CB4B4B" },
    { value: 26, label: "Emails received", color: "#DD9553" },
  ];

  return (
    <div>
      <Typography
        sx={{ fontSize: "18px", fontWeight: 600, marginBottom: "12px" }}
      >
        Summary (last 7 days)
      </Typography>
      <Grid container spacing={0} alignItems="center">
        {data.map((item, index) => (
          <GridItem
            key={index}
            value={item.value}
            label={item.label}
            color={item.color}
            // showDivider={index < data.length - 1}
          />
        ))}
      </Grid>
    </div>
  );
};

export default Summary;
