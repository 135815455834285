import React from "react";
import { Box, Avatar, Typography } from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";
import { formateHour } from "../../../../CustomHooks/formateDates";
import HoverDropDown from "./HoverDropDown";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
const SendMessage = ({
  data,

  hoverId,
  handleOpen,
  handleClose,
  open,
  anchorEl,
  setHoverId,
  messageOptions,
}) => {
  console.log("sendsocketdata", data);

  function renderIcon(status) {
    switch (status) {
      case "sent":
        return (
          <DoneIcon
            sx={{ fontSize: "11px", color: "black", marginLeft: "2px" }}
          />
        );
      case "read":
        return (
          <DoneAllIcon
            sx={{ fontSize: "11px", color: "#87CEEB", marginLeft: "2px" }}
          />
        );
      case "delivered":
        return (
          <DoneAllIcon
            sx={{ fontSize: "11px", color: "grey", marginLeft: "2px" }}
          />
        );
      case "pending":
        return (
          <AccessTimeIcon
            sx={{ fontSize: "11px", color: "grey", marginLeft: "2px" }}
          />
        );
      default:
        return <></>;
    }
  }
  console.log("senderdata", data, data.message_id);

  return (
    <Box
      display={"flex"}
      justifyContent={"flex-end"}
      paddingRight={"14px"}
      paddingTop={2}
      onMouseEnter={() => setHoverId(data._id)}
      onMouseLeave={() => setHoverId("")}
    >
      <Box
        height={"auto"}
        sx={{ background: "#FFFFFF" }}
        width={"60%"}
        p={1}
        border={"1px solid rgba(0, 0, 0, 0.1)"}
        borderRadius={1}
      >
        <Box display={"flex"} justifyContent={"space-between"}>
          {" "}
          {data?.ContentType === "file" ? (
            <>
              <img alt="server data" />
              <p>i am image</p>
            </>
          ) : (
            <Typography color={"#000000"} fontWeight={500} fontSize={11}>
              {data?.content}
            </Typography>
          )}
          {hoverId === data._id && !data.is_deleted && (
            <HoverDropDown
              open={open}
              handleClose={handleClose}
              handleOpen={handleOpen}
              anchorEl={anchorEl}
              messageOptions={messageOptions}
              messageId={data.message_id}
              conversationId={data.conversation_id}
            />
          )}
        </Box>
        <Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Typography color={"#D9D9D9"} fontWeight={500} fontSize={10}>
              {formateHour(data?.time)}
            </Typography>
            {renderIcon(data?.messageStatus)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SendMessage;
