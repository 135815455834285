import React, { useState } from "react";
import {
  Grid,
  FormControl,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import facebookAds from "./Ads.module.scss";
import { FaFacebookF } from "react-icons/fa";

const style = {
  margin: "5px 25px 0 10px ",
  "& .MuiInputBase-root": {
    height: "45px", // Set your desired height
  },
  "& .MuiOutlinedInput-root": {
    padding: "0", // Adjust padding if needed
  },
  "& .MuiInputLabel-root": {
    top: "-4px", // Adjust label position if needed
  },
};
const iconStyle = {
  color: "#fff",
  fontSize: "45px",
  backgroundColor: "#1877F2",
  padding: "10px",
  borderRadius: "30px",
};
const FacebookAds = () => {
  const [textValue, setTextValue] = useState("");

  const handleCopy = () => {
    navigator.clipboard.writeText(textValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={facebookAds.fbheader}>
          <Typography sx={{ fontSize: "20px", fontWeight: 400 }}>
            Facebook ad source
          </Typography>
          <div className={facebookAds.buttons}>FEEDBACK</div>
        </div>
      </Grid>
      <Grid item xs={12}>
        {" "}
        <div
          className={facebookAds.container}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            padding: "25px 10px",
          }}
        >
          <FaFacebookF
            style={{ ...iconStyle, fontSize: "70px", borderRadius: "50%" }}
          />
          <div>
            <Typography sx={{ fontSize: "24px", fontWeight: 400 }}>
              Connect Facebook to Sales Intelligence
            </Typography>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 400, marginTop: "7px" }}
            >
              Report will show the Facebook marketing ROI and the performance of
              your sales respresentatives.
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={facebookAds.container}>
          <div className={facebookAds.header}>Log on to Facebook</div>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 400, marginTop: "15px" }}
          >
            Use Facebook account to log on
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              margin: "15px 0 15px 10px",
            }}
          >
            <FaFacebookF style={iconStyle} />
            <div className={facebookAds.buttons}>CONNECT</div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={facebookAds.container}>
          <div className={facebookAds.header}>Source Name</div>
          <FormControl fullWidth margin="normal">
            <TextField variant="outlined" placeholder="Facebook" sx={style} />
          </FormControl>
          <Typography
            sx={{
              margin: "0px 0 10px 10px",
              fontSize: "14px",
              color: "#878787",
            }}
          >
            As will be seen in the report. Example: Newsletters, Radio,
            Instagram post
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={facebookAds.container}>
          <div className={facebookAds.header}>UTM parameters</div>
          <div className={facebookAds.utmtextfield}>
            <div className={facebookAds.utmtext}>utm_source</div>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                placeholder="+ Add UTM parameter"
                sx={style}
              />
            </FormControl>
          </div>
          <Typography
            sx={{
              margin: "0px 0 10px 10px",
              fontSize: "14px",
              color: "#878787",
            }}
          >
            Click source. Example: email, newsletter, banner, Instagram
          </Typography>
          <div className={facebookAds.utmtextfield}>
            <div className={facebookAds.utmtext}>utm_control</div>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                value={textValue}
                onChange={(e) => setTextValue(e.target.value)}
                sx={style}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography
                        onClick={handleCopy}
                        sx={{
                          marginRight: "15px",
                          cursor: "pointer",
                          fontSize: "16px",
                          fontWeight: 600,
                        }}
                      >
                        Copy
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </div>
          <Typography
            sx={{
              margin: "0px 0 10px 10px",
              fontSize: "14px",
              color: "#878787",
            }}
          >
            You have to configure your ad account to get a correct detailed cost
            report
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default FacebookAds;
