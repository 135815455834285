// hooks/useSocket.js
import { useEffect, useState } from "react";
import io from "socket.io-client";
import useUser from "../../store/userStore";
import { getBaseUrl } from "./OptionsUtils";
import useSelectedWorkSpace from "../../store/selectedWorkSpaceStore";

const SERVER = `${process.env.REACT_APP_TEAM_CHAT_BASEURL}`;
// const SERVER = getBaseUrl();
console.log("serverurl", SERVER);
const useSocket = () => {
  const { getToken, getUserId } = useUser();
  const clientid = getUserId();
  const { getSelectedWorkSpace } = useSelectedWorkSpace();

  const Workspaceid = getSelectedWorkSpace();

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const token = getToken();

    const newSocket = io(SERVER, {
      query: {
        token: `Bearer ${token}`,
        userId: clientid,
        workspaceId: Workspaceid?.id,
      },
    });
    newSocket.on("connect", () => {
      alert("socket connected");
      console.log("Socket connected");
    });

    newSocket.on("disconnect", () => {
      console.log("Socket disconnected");
    });
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [getToken]);

  return socket;
};

export default useSocket;
