import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Modal,
  Typography,
  IconButton,
  Box,
  TextField,
  FormControl,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import UsersBar from "./UsersBar";
import GroupName from "./GroupName";
import { useUsersList } from "../../../../CustomHooks/useUsersList";
import { ObjectId } from "bson";
import toast from "react-hot-toast";
import SelectedUser from "./SelectedUser";
import { useWorSpaceRoom } from "../../../../store/teamChatStore";
import useUser from "../../../../../store/userStore";
import useSelectedWorkSpace from "../../../../../store/selectedWorkSpaceStore";
import useSocket from "../../../../Utils/Socket";

const CreateGroup = ({ open, handleClickClose }) => {
  const [search, setSearch] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState([]);
  const [groupName, setGroupName] = useState("");
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useUsersList(10);
  const usersData = data?.pages?.flatMap((page) => page.data) || [];

  const filteredUsers = usersData.filter(
    (user) => !selectedUserIds.includes(user.userId)
  );

  const observer = useRef();
  const lastUserRef = useCallback(
    (node) => {
      if (isFetchingNextPage) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  useEffect(() => {
    if (filteredUsers.length > 0 && selectedUserId === null) {
      setSelectedUserId(filteredUsers[0].userId);
    }
  }, [filteredUsers, selectedUserId]);

  const handleUserClick = (user) => {
    if (!selectedUserIds.includes(user.userId)) {
      setSelectedUserIds([...selectedUserIds, user.userId]);
      setSelectedUserData([...selectedUserData, user]);
    }
  };

  const handleChipDelete = (userId) => {
    setSelectedUserIds(selectedUserIds.filter((id) => id !== userId));
    setSelectedUserData(
      selectedUserData.filter((user) => user.userId !== userId)
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setSelectedUserId((prevId) => {
        const currentIndex = filteredUsers.findIndex(
          (u) => u.userId === prevId
        );
        const nextIndex = (currentIndex + 1) % filteredUsers.length;
        return filteredUsers[nextIndex]?.userId || null;
      });
    } else if (e.key === "ArrowUp") {
      setSelectedUserId((prevId) => {
        const currentIndex = filteredUsers.findIndex(
          (u) => u.userId === prevId
        );
        const prevIndex =
          (currentIndex - 1 + filteredUsers.length) % filteredUsers.length;
        return filteredUsers[prevIndex]?.userId || null;
      });
    } else if (e.key === "Enter") {
      const selectedUser = filteredUsers.find(
        (u) => u.userId === selectedUserId
      );
      if (selectedUser) {
        handleUserClick(selectedUser);
      }
    }
  };

  const [loading, setLoading] = useState(false);
  const Socket = useSocket();
  const { workSpaceRoomId } = useWorSpaceRoom();
  const { getUserId } = useUser();
  const userId = getUserId();
  const { getSelectedWorkSpace } = useSelectedWorkSpace();
  const Workspaceid = getSelectedWorkSpace();

  function handleGroupCreate() {
    const messageId = new ObjectId().toString();
    setLoading(true);
    let obj = {
      groupName: groupName,
      membersList: selectedUserIds,
      userId,
      description: "",
      roomId: workSpaceRoomId,
      group_avatar: "",
      workspaceId: Workspaceid.id,
      messageId,
    };
    console.log("groupcreation", obj);

    Socket.emit("create_group", obj, (msg) => {
      handleClickClose();
      if (msg.success) {
        toast.success(msg.message);
        setGroupName("");
        setSelectedUserData([]);
        setSelectedUserId([]);
        setSelectedUserIds([]);
      } else {
        toast.error(msg.message);
      }
      setLoading(false);
    });
  }

  return (
    <Modal
      open={open}
      onClose={handleClickClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        position={"absolute"}
        top={"7%"}
        right={"3%"}
        width={"26%"}
        maxHeight={"70%"}
        component={"Paper"}
        borderRadius={"3px"}
        overflow={"hidden auto"}
        sx={{ background: "#ffff" }}
        onKeyDown={handleKeyDown}
        tabIndex={0}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={1}
        >
          <Typography
            component={"p"}
            fontWeight={500}
            fontSize={(theme) => theme.typography.teamChatTitle.fontSize}
          >
            Select User To Start A New Chat
          </Typography>
          <IconButton onClick={handleClickClose} sx={{ fontSize: "16px" }}>
            <CloseIcon fontSize="16px" />
          </IconButton>
        </Box>
        <Box width={"100%"} py={2} px={1}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {search.length === 0 ? (
                      <SearchIcon />
                    ) : (
                      <CloseIcon
                        onClick={() => setSearch("")}
                        sx={{
                          cursor: "pointer",
                          background: "#acacac42",
                          borderRadius: "3px",
                        }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  padding: "8px",
                  fontSize: "14px",
                },
              }}
            />
          </FormControl>
        </Box>
        <SelectedUser
          selectedUserData={selectedUserData}
          handleChipDelete={handleChipDelete}
        />
        <Box maxHeight={"200px"} overflow={"hidden auto"} px={1}>
          {filteredUsers.map((user, index) => {
            if (index === filteredUsers.length - 1) {
              return (
                <div ref={lastUserRef} key={`${user.userId}-ref`}>
                  <UsersBar
                    user={user}
                    selected={user.userId === selectedUserId}
                    onClick={() => handleUserClick(user)}
                  />
                </div>
              );
            } else {
              return (
                <UsersBar
                  key={user.userId}
                  user={user}
                  selected={user.userId === selectedUserId}
                  onClick={() => handleUserClick(user)}
                />
              );
            }
          })}
          {isFetchingNextPage && <Typography>Loading more...</Typography>}
        </Box>
        {selectedUserIds.length > 0 && <Box></Box>}
        <GroupName
          groupName={groupName}
          setGroupName={setGroupName}
          users={selectedUserIds}
          loading={loading}
          handleGroupCreate={handleGroupCreate}
        />
      </Box>
    </Modal>
  );
};

export default CreateGroup;
