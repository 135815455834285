import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Divider,
  IconButton,
  InputAdornment,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import AttachFileIcon from "@mui/icons-material/AttachFile"; // Icon for attachment
import Picker from "emoji-picker-react";
import SendMessage from "./SendMessage";
import ReceivedMsg from "./ReceivedMsg";
import { useReceiverId } from "../../../../store/teamChatStore";
import { useGetMessages } from "../../../../CustomHooks/useGetMessages";
import { ObjectId } from "bson";
import useSelectedWorkSpace from "../../../../../store/selectedWorkSpaceStore";
import InfiniteScroll from "react-infinite-scroll-component";
import useUser from "../../../../../store/userStore";
import PinMessage from "./PinMessage";
import axios from "axios";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";

const ChatBody = ({
  socket,
  roomId,
  message,
  setMessage,
  receiverId,
  senderId,
}) => {
  const { receiverData } = useReceiverId();
  const queryClient = useQueryClient();

  const { user } = useUser();
  const { getSelectedWorkSpace } = useSelectedWorkSpace();
  const Workspaceid = getSelectedWorkSpace();
  const workSpaceID = Workspaceid?.id;
  const convoId = receiverData?.convoId;
  const myroom = roomId;
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetMessages(10, convoId);

  const [messages, setMessages] = useState([]);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const openDropDown = Boolean(anchorEl);
  const containerRef = useRef(null);

  const fetchOlderMessages = useCallback(async () => {
    if (isFetchingNextPage || !hasNextPage) return;
    try {
      await fetchNextPage();
    } catch (error) {
      console.error("Error fetching older messages:", error);
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (data?.pages) {
      const newMessages = data.pages.flatMap((page) => page.data || []);
      setMessages((prevMessages) => {
        const existingMessageIds = new Set(prevMessages.map((msg) => msg._id));
        return [
          ...prevMessages,
          ...newMessages.filter((msg) => !existingMessageIds.has(msg._id)),
        ];
      });
    }
  }, [data]);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        setIsAtBottom(scrollTop + clientHeight >= scrollHeight - 1);
      }
    };

    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isAtBottom && containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages, isAtBottom]);

  const handleReceiveMsg = useCallback(
    (response) => {
      const newMessage = response.data;
      const msgData = {
        time: new Date(),
        convoId: newMessage.conversation_id,
        roomId: myroom,
        userId: receiverId,
        messageIds: [newMessage.message_id],
      };
      setMessages((prevMessage) => [...prevMessage, newMessage]);
      socket.emit("message_delivered", msgData);
    },
    [roomId]
  );

  const handleDeleteMsg = useCallback((response) => {
    handleClose();
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        (msg._id || msg.message_id) === response.messageId
          ? { ...msg, content: "Message was deleted", is_deleted: true }
          : msg
      )
    );
  }, []);

  function handleMessageReceived(response) {
    const { messageIds } = response;
    setMessages((prevMessages) => {
      return prevMessages.map((msg) => {
        if (messageIds.includes(msg.messageId)) {
          return { ...msg, messageStatus: "delivered" };
        }
        return msg;
      });
    });
  }

  useEffect(() => {
    if (!roomId || !socket) return;

    socket.on("receive_message", handleReceiveMsg);
    socket.on("message_deleted", handleDeleteMsg);
    socket.on("message_delivered", handleMessageReceived);

    return () => {
      socket.off("receive_message", handleReceiveMsg);
      socket.off("message_deleted", handleDeleteMsg);
      socket.off("message_delivered", handleMessageReceived);
    };
  }, [roomId, socket, handleReceiveMsg]);

  const sendMessage = (isImage, data) => {
    if (!roomId || !socket) return;
    const messageId = new ObjectId().toString();
    let sendData = {
      roomId,
      message,
      messageType: "sent",
      sender: senderId,
      receiver: receiverId,
      conversationId: convoId,
      messageId,
      workspaceId: workSpaceID,
      isGroupChat: false,
      groupId: "",
      ContentType: "text",
    };
    let appendMsg = {
      content: message,
      conversation_id: convoId,
      messageStatus: "",
      messageType: "sent",
      properties: [],
      time: new Date(),
      messageId,
      is_deleted: false,
      ContentType: "text",
    };
    if (isImage) {
      alert("send socket");
      sendData.mimeType = data.mimetype;
      sendData.originalFilename = data.originalname;
      sendData.size = data.size;
      sendData.originalImageKey = data.original_image_key;
      sendData.thumbnailkey = data.thumbnail_key;
      sendData.thumbnailUrl = data.thumbnailUrl;
      sendData.originalUrl = data.originalUrl;
      sendData.content = "";
      sendData.ContentType = "file";

      appendMsg.mimeType = data.mimetype;
      sendData.originalFilename = data.originalname;
      appendMsg.size = data.size;
      appendMsg.originalImageKey = data.original_image_key;
      appendMsg.thumbnailkey = data.thumbnail_key;
      appendMsg.thumbnailUrl = data.thumbnailUrl;
      appendMsg.originalUrl = data.originalUrl;
      appendMsg.content = "";
      appendMsg.ContentType = "file";
    }
    console.log("mysendmsgdata", sendData);

    socket.emit("send_message", sendData, (ack) => {
      if (ack.success) {
        appendMsg.messageStatus = "sent";
      } else {
        appendMsg.messageStatus = "pending";
      }
      setMessages((prevMessage) => [...prevMessage, appendMsg]);
    });

    setMessage("");
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    updateProfile(file);
  };
  function updateProfile(file) {
    let formdata = new FormData();
    console.log("companydorm", file);

    formdata.append("file", file);
    alert("trigg");
    axios
      .post(
        `${process.env.REACT_APP_TEAM_CHAT_BASEURL}/api/v1/messages/upload/file`,
        formdata
      )
      .then((result) => {
        console.log("myresultttt", result);

        sendMessage(true, result.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }

  console.log("sendsocketdata", messages);
  const deleteMessage = (conversationId, messageId) => {
    if (!roomId || !socket) return;

    const deleteData = {
      roomId,
      messageId,
      conversationId,
    };

    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        (msg._id || msg.message_id) === messageId
          ? { ...msg, content: "Message was deleted" }
          : msg
      )
    );
    socket.emit("delete_message", deleteData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendMessage(false);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [hoverId, setHoverId] = useState("");
  const messageOptions = [
    { label: "Delete", actionFunc: deleteMessage, id: "#delete" },
  ];

  const TypingStatus = () => {
    if (!roomId || !socket) return;
    const typingData = {
      roomId,
      userName: `${user?.fullName}`,
    };

    socket.emit("typing", typingData);
  };

  function handleMessages(e) {
    setMessage(e.target.value);
    TypingStatus();
  }

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prev) => !prev);
  };

  const handleEmojiClick = (emojiData) => {
    setMessage((prevMessage) => prevMessage + emojiData.emoji);
    TypingStatus();
    setShowEmojiPicker(false);
  };

  const getDeliveredAndUnreadMessageIds = () => {
    return messages
      .filter(
        (msg) => msg.messageType === "delivered" || msg.messageType === "unread"
      )
      .map((msg) => msg.messageId);
  };

  return (
    <>
      <Box
        id="scrollable-chat"
        sx={{
          height: "84%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column-reverse",
        }}
        paddingBottom={2}
        ref={containerRef}
      >
        <InfiniteScroll
          dataLength={messages.length}
          next={fetchOlderMessages}
          hasMore={hasNextPage}
          scrollableTarget="scrollable-chat"
          inverse={true}
        >
          <div>
            {messages.map((msg) => (
              <div key={msg._id} style={{ marginBottom: "8px" }}>
                {msg?.messageType?.toLowerCase() === "sent" ? (
                  <SendMessage
                    data={msg}
                    hoverId={hoverId}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                    open={openDropDown}
                    anchorEl={anchorEl}
                    setHoverId={setHoverId}
                    messageOptions={messageOptions}
                  />
                ) : (
                  <ReceivedMsg
                    data={msg}
                    hoverId={hoverId}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                    open={openDropDown}
                    anchorEl={anchorEl}
                    setHoverId={setHoverId}
                    messageOptions={messageOptions}
                    isGroupChat={receiverData.is_group_chat}
                  />
                )}
              </div>
            ))}
          </div>
        </InfiniteScroll>
      </Box>
      <Divider />
      <form onSubmit={handleSubmit}>
        <Box
          height={"8%"}
          display="flex"
          alignItems="center"
          px={2}
          paddingTop={"5px"}
        >
          <TextField
            size="small"
            variant="outlined"
            sx={{ flexGrow: 1 }}
            value={message}
            onChange={handleMessages}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <label htmlFor="upload-image">
                    <IconButton component="span">
                      <AttachFileIcon />
                    </IconButton>
                  </label>
                  <IconButton onClick={toggleEmojiPicker}>
                    <EmojiEmotionsIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="upload-image"
            type="file"
            onChange={handleImageUpload}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{
              borderRadius: "50%",
              minWidth: 40,
              width: 40,
              height: 40,
              padding: 0,
              bgcolor: "blue",
              marginLeft: 1,
            }}
            disabled={!message?.trim()}
            type="submit"
          >
            <SendIcon sx={{ color: "white" }} />
          </Button>
        </Box>
      </form>
      {showEmojiPicker && (
        <Box position="absolute" bottom="60px" right="20px">
          <Picker onEmojiClick={handleEmojiClick} />
        </Box>
      )}
    </>
  );
};

export default ChatBody;
