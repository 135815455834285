import { create } from 'zustand'
import { persist } from 'zustand/middleware';

export const useCRMSidebarStore = create((set) => ({
    isSidebarOpen: false,
    setIsSidebarOpen: (isOpen) => set((state) => ({ isSidebarOpen: isOpen }))
}));

export const useCRMSettingsStore = create((set) => ({
    freezeActions: false,
    toggleFreezeActions: (freeze) => set((state) => ({ freezeActions: freeze }))
}));

const pipelineInitialState = {
    isBoardView: true,
    isDrawerOpen: true,
    selectedBoardData: null,
    selectedTableData: null,
    resetBulkSelection: false,
    boardSortData: null,
    displayedColumns: {
        pipelineBoard: [],
        pipelineTable: []
    }
}
export const useCrmPipelinesStore = create()(
    persist(
        (set) => ({
            ...pipelineInitialState,
            setIsBoardView: (boardView) => set((state) => ({ isBoardView: boardView })),
            setIsDrawerOpen: (drawerOpen) => set((state) => ({ isDrawerOpen: drawerOpen })),
            setSelectedBoardData: (data) => set((state) => ({ selectedBoardData: data })),
            setSelectedTableData: (ids) => set((state) => ({ selectedTableData: ids })),
            setResetBulkSelection: (resetBulk) => set((state) => ({ resetBulkSelection: resetBulk })),
            setBoardSortData: (sort) => set((state) => ({ boardSortData: sort })),
            setDisplayedColumns: (key, data) => set((state) => ({ displayedColumns: { ...state.displayedColumns, [key]: data } })),
            resetState: () => set((state) => (pipelineInitialState))
        }),
        {
            name: "crm-deals",
        }
    )
);

const contactsInitialState = {
    resetBulkSelection: false,
    selectedTableData: null,
    displayedColumns: [],
    contactsFilter: []
}
export const useCrmContactsStore = create()(
    persist(
        (set) => ({
            ...contactsInitialState,
            setSelectedTableData: (ids) => set((state) => ({ selectedTableData: ids })),
            setResetBulkSelection: (resetBulk) => set((state) => ({ resetBulkSelection: resetBulk })),
            setDisplayedColumns: (data) => set((state) => ({ displayedColumns: data })),
            resetState: () => set((state) => (contactsInitialState)),
            setContactsFilter: (data) => set((state) => ({ contactsFilter: data })),
        }),
        {
            name: "crm-contacts",
        }
    )
);

const companyInitialState = {
    resetBulkSelection: false,
    selectedTableData: null,
    displayedColumns: [],
    companyFilter: []
}
export const useCrmCompanyStore = create()(
    persist(
        (set) => ({
            ...companyInitialState,
            setSelectedTableData: (ids) => set((state) => ({ selectedTableData: ids })),
            setResetBulkSelection: (resetBulk) => set((state) => ({ resetBulkSelection: resetBulk })),
            setDisplayedColumns: (data) => set((state) => ({ displayedColumns: data })),
            resetState: () => set((state) => (companyInitialState)),
            setCompanyFilter: (data) => set((state) => ({ companyFilter: data })),
        }),
        {
            name: "crm-companies",
        }
    )
);

const productsInitialState = {
    resetBulkSelection: false,
    selectedTableData: null,
    displayedColumns: [],
    productsFilter: []
}
export const useCrmProductsStore = create()(
    persist(
        (set) => ({
            ...productsInitialState,
            setSelectedTableData: (ids) => set((state) => ({ selectedTableData: ids })),
            setResetBulkSelection: (resetBulk) => set((state) => ({ resetBulkSelection: resetBulk })),
            setDisplayedColumns: (data) => set((state) => ({ displayedColumns: data })),
            resetState: () => set((state) => (productsInitialState)),
            setProductsFilter: (data) => set((state) => ({ productsFilter: data })),
        }),
        {
            name: "crm-products",
        }
    )
);