import { Box, Paper } from "@mui/material";
import React, { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";

import {
  useOnlineStatusStore,
  useOpenChatStore,
  useWorSpaceRoom,
} from "./store/teamChatStore";
import ChatWindow from "./Components/Maximized";
import ChatProfiles from "./Components/Minimized";
import useSocket from "./Utils/Socket";
import useSelectedWorkSpace from "../store/selectedWorkSpaceStore";
import useUser from "../store/userStore";

const TeamChat = () => {
  const { openChat } = useOpenChatStore();
  const queryClient = useQueryClient();

  const socket = useSocket();
  const { getSelectedWorkSpace } = useSelectedWorkSpace();
  const { setworkSpaceRoomId } = useWorSpaceRoom();
  const Workspaceid = getSelectedWorkSpace();
  const { getUserId } = useUser();
  const userId = getUserId();
  const { addOnlineStatusId, removeOnlineStatusId } = useOnlineStatusStore();
  function handleOnlineStatus(response) {
    addOnlineStatusId(response);
    console.log("userres", response);
  }
  function handleOfflineStatus(response) {
    removeOnlineStatusId(response);

    console.log("userres", response);
  }
  function handleReceivedMsg(response){
    console.log("gettingres",response)
    const msgData = {
      time: new Date(),
      convoId: response.conversation_id,
      // roomId: myroom,
      // userId: receiverId,
      messageIds: [response.message_id],
    };
    socket.emit("message_delivered",msgData)
  }
  useEffect(() => {
    if (!socket) return;
    const joinData = {
      workspaceId: Workspaceid?.id,
    };

    const handleConnect = (response) => {
      setworkSpaceRoomId(response.roomId);
    };

    socket.emit("join_workspace", joinData);
   
    socket.on("roomJoined", handleConnect);
    socket.on("user_online", handleOnlineStatus);
    socket.on("user_offline", handleOfflineStatus);
    socket.on("message_received", handleReceivedMsg);
    return () => {
      socket.off("roomJoined", handleConnect);
      socket.off("user_online", handleOnlineStatus);
      socket.off("user_offline", handleOfflineStatus);
      socket.off("message_received", handleReceivedMsg);

    };
  }, [socket, Workspaceid]);

  useEffect(() => {
    if (!socket) return;
    const handleConnect = (response) => {
      const isMember = response.data.some((data) => data === userId);

      if (isMember) {
        queryClient.invalidateQueries(["getMessageList"]);
      }
    };
    socket.on("refetch_chat_list", handleConnect);

    return () => {
      socket.off("refetch_chat_list", handleConnect);
    };
  }, [socket]);
  return (
    <Paper
      elevation={1}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 10,
      }}
    >
      <Box width={openChat ? "22vw" : "56px"} height={"calc(100vh - 40px)"}>
        {openChat ? <ChatWindow /> : <ChatProfiles />}
      </Box>
    </Paper>
  );
};

export default TeamChat;
