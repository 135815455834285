import { Box } from "@mui/material";
import React from "react";

const FollowUp = () => {
  return (
    <Box
      sx={{
        padding: "20px",
        overflowY: "auto",
        height: "calc(100% - 40px)",
        overflowX: "hidden",
      }}
    >
      FollowUp
    </Box>
  );
};

export default FollowUp;
