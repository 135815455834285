import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Group from "../../../../component/images/Group.png";

const EmailPreview = () => {
  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "calc(100% - 40px)",
        overflowX: "hidden",
      }}
    >
      <Box sx={{ padding: "20px" }}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          sx={{ marginBottom: "15px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span style={{ color: "#CCCCCC", fontSize: "14px" }}>From</span>{" "}
                <span
                  style={{
                    padding: "0 5px 4px 5px",
                    fontSize: "25px",
                    color: "#E6E6E6",
                  }}
                >
                  |
                </span>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span style={{ color: "#CCCCCC", fontSize: "14px" }}>To</span>{" "}
                <span
                  style={{
                    padding: "0 5px 4px 23px",
                    fontSize: "25px",
                    color: "#E6E6E6",
                  }}
                >
                  |
                </span>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ paddingTop: "20px" }}>
          <Box
            sx={{
              display: "flex",
              paddingBottom: "15px",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
              To :{" "}
            </Typography>
            <Typography
              sx={{ fontSize: "14px" }}
            >{`Example contact <example.googlr.com>`}</Typography>
          </Box>
          <Box sx={{ display: "flex", paddingBottom: "15px", gap: "5px" }}>
            <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
              Subject :
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>Re : Test</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "10px",
          }}
        >
          <img
            src={Group}
            alt="Group"
            style={{ height: "130px", width: "130px" }}
          />
          <Typography
            sx={{ fontSize: "14px", paddingTop: "15px", color: "#A1A3A7" }}
          >
            Write someting to generate the Email preview
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          borderTop: "1px solid var(--light-border-color)",
          padding: "15px 35px 15px 20px",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            textTransform: "none",
            color: "#000AFF",
            borderColor: "#000AFF",
            "&:hover": { color: "#000AFF" },
          }}
        >
          Send Test Email
        </Button>
      </Box>
    </Box>
  );
};

export default EmailPreview;
