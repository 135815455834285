import axios from "axios";
const mailsnow_url = process.env.REACT_APP_MAILS_NOW_BASEURL;

// export const sequenceList = async () =>{
//     return await axios.get(`${mailsnow_url}/sequence/sequence-list`)
//   }
export const sequenceList = async ({ page = 1 }) => {
  try {
    const response = await axios.get(`${mailsnow_url}/sequence/sequence-list`, {
      params: { page },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.message || "Failed to get sequence list"
      );
    } else {
      throw new Error(error.message || "Unknown error occurred");
    }
  }
};

export const sequenceNameStatus = async (sequence_id) => {
  return await axios.get(
    `${mailsnow_url}/sequence/sequence-name-status/${sequence_id}`
  );
};

export const stepList = async (sequence_id) => {
  return await axios.get(`${mailsnow_url}/sequence/step-list/${sequence_id}`);
};

export const createSequence = async (data) => {
  return await axios.post(`${mailsnow_url}/sequence/create`, data);
};

export const createStep = async (data) => {
  return await axios.post(`${mailsnow_url}/sequence/add-step`, data);
};

export const sequenceMatrix = async (sequence_id) => {
  return await axios.get(
    `${mailsnow_url}/sequence/sequence-matrix/${sequence_id}`
  );
};

export const prospectData = async ({ sequence_id, pageParam }) => {
  try {
    const response = await axios.get(
      `${mailsnow_url}/sequence/prospect-data/${sequence_id}`,
      {
        params: {
          page: pageParam,
        },
      }
    );
    return {
      data: response.data,
      nextPage: response.data.nextPage,
      hasNextPage: response.data.hasNextPage,
    };
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.message || "Failed to get prospect data"
      );
    } else {
      throw new Error(error.message || "Unknown error occurred");
    }
  }
};
export const sequenceDelete = async (smtp_id, sequence_id) => {
  try {
    return await axios.delete(
      `${mailsnow_url}/sequence/sequence-mail/${smtp_id}/${sequence_id}`
    );
  } catch (error) {
    console.log("ddd", error);
    const errorMessage = error.response && error.response.data 
      ? error.response.data.message || "Failed to delete sequence"
      : error.message || "Unknown error occurred";
    throw new Error(errorMessage);
  }
};


export const stepDelete = async (sequence_id, stepId) => {
  try {
    return await axios.delete(
      `${mailsnow_url}/sequence/step/${stepId}/${sequence_id}`
    );
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.message || "Failed to delete variant"
      );
    } else {
      throw new Error(error.message || "Unknown error occurred");
    }
  }
};

export const stepVariantDelete = async (stepId, variant_id) => {
  try {
    return await axios.delete(
      `${mailsnow_url}/sequence/variant/${variant_id}/${stepId}`
    );
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.message || "Failed to delete variant"
      );
    } else {
      throw new Error(error.message || "Unknown error occurred");
    }
  }
};

export const crmContacts = async () => {
  return await axios.get(`${mailsnow_url}/sequence/crm-contacts`);
};

export const getStep = async (sequence_id) => {
  return await axios.get(`${mailsnow_url}/sequence/step-index/${sequence_id}`);
};

export const addProspect = async (data) => {
  return await axios.post(`${mailsnow_url}/sequence/add-prospects`, data);
};

export const getEmailProvider = async () => {
  return await axios.get(`${mailsnow_url}/sequence/emailprovider`);
};

export const smtpConnectionTest = async (data) => {
  return await axios.post(
    `${mailsnow_url}/sequence/smtp-connection-test`,
    data
  );
};

export const imapConnectionTest = async (data) => {
  return await axios.post(
    `${mailsnow_url}/sequence/imap-connection-test`,
    data
  );
};

export const addEmailAccount = async (data) => {
  return await axios.post(`${mailsnow_url}/sequence/add-smtp-imap`, data);
};

export const addEmailAccountGlobal = async (data) => {
  return await axios.post(`${mailsnow_url}/sequence/add-smtp-global`, data);
};

export const getEmailAccounts = async (sequence_id) => {
  return await axios.get(
    `${mailsnow_url}/sequence/email-accounts/${sequence_id}`
  );
};

export const updateSequenceStatus = async (data) => {
  return await axios.put(
    `${mailsnow_url}/sequence/update-sequence-status/${data.sequence_id}`,
    data
  );
};

export const intervalRamUp = async (id) => {
  return await axios.get(`${mailsnow_url}/sequence/interval-time/${id}`);
};

export const updateRampUpStatus = async (data) => {
  return await axios.put(
    `${mailsnow_url}/sequence/update-ramup-status/${data.id}`,
    data
  );
};

export const updateRampUpValue = async (data) => {
  return await axios.put(
    `${mailsnow_url}/sequence/update-ramup-value/${data.id}`,
    data.value
  );
};

export const getSchedule = async () => {
  return await axios.get(`${mailsnow_url}/sequence/schedule`);
};

export const updateSchedule = async (data) => {
  return await axios.put(
    `${mailsnow_url}/sequence/update-schedule/${data.id}`,
    data
  );
};

export const addSchedule = async () => {
  return await axios.post(`${mailsnow_url}/sequence/add-schedule`);
};

export const addVariant = async (data) => {
  return await axios.post(`${mailsnow_url}/sequence/add-variants`, data);
};

export const getSequenceScore = async (sequence_id) => {
  try {
    return await axios.get(
      `${mailsnow_url}/sequence/sequence-score/${sequence_id}`
    );
  } catch (error) {
    return { status: error?.response?.status };
  }
};

export const getEmailSetup = async (smtp_id) => {
  try {
    return await axios.get(`${mailsnow_url}/sequence/email-setup/${smtp_id}`);
  } catch (error) {
    return { status: error?.response?.status };
  }
};

export const getSequenceSetup = async (sequence_id) => {
  try {
    return await axios.get(
      `${mailsnow_url}/sequence/sequence-setup/${sequence_id}`
    );
  } catch (error) {
    return { status: error?.response?.status };
  }
};

export const getEmailWriting = async (sequence_id) => {
  try {
    return await axios.get(
      `${mailsnow_url}/sequence/email-writing/${sequence_id}`
    );
  } catch (error) {
    return { status: error?.response?.status };
  }
};

export const uploadCsvProspect = async (data) => {
  try {
    return await axios.post(
      `${mailsnow_url}/sequence/uploadcsv-prospect`,
      data
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const emailReportCount = async (sequence_id) => {
  try {
    return await axios.get(
      `${mailsnow_url}/sequence/email-report-count/${sequence_id}`
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const emailReport = async ({ sequence_id, pageParam, step }) => {
  try {
    const response = await axios.get(
      `${mailsnow_url}/sequence/email-report/${sequence_id}`,
      {
        params: {
          step: step === 0 ? "all" : step,
          page: pageParam,
        },
      }
    );
    console.log("API Response:", response.data);
    return {
      data: response.data.result,
      hasNextPage: response.data.hasNextPage,
      currentPage: response.data.currentPage,
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const updateSequenceSchedule = async (data) => {
  return await axios.put(
    `${mailsnow_url}/sequence/update-sequence-schedule?sequence_id=${data.sequence_id}&schedule_id=${data.schedule_id}`
  );
};

export const sequenceById = async (sequence_id) => {
  try {
    return await axios.get(
      `${mailsnow_url}/sequence/sequence-data/${sequence_id}`
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const createContact = async (data) => {
  return await axios.post(`${mailsnow_url}/sequence/create-contact`, data);
};

export const globalEmailAccounts = async () => {
  try {
    return await axios.get(`${mailsnow_url}/sequence/global-email-accounts`);
  } catch (error) {
    throw new Error(error);
  }
};

export const addCustomDomain = async (data) => {
  return await axios.post(
    `${mailsnow_url}/sequence/custom-tracking-domain`,
    data
  );
};

export const getCustomTrackingDomain = async () => {
  try {
    return await axios.get(`${mailsnow_url}/sequence/custom-track-domain`);
  } catch (error) {
    throw new Error(error);
  }
};

export const updateSmtp = async (data) => {
  return await axios.put(
    `${mailsnow_url}/sequence/update-smtp/${data.doc_id}`,
    data
  );
};

export const makeScheduleDefault = async (data) => {
  return await axios.put(
    `${mailsnow_url}/sequence/make-schedule-default/${data.doc_id}`
  );
};

export const imapDetails = async (data) => {
  try {
    return await axios.get(
      `${mailsnow_url}/sequence/imap-details?email_address=${data}`
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const prospectSendEmailLog = async (data) => {
  try {
    return await axios.get(
      `${mailsnow_url}/sequence/prospect-email-send-log?sequence_id=${data?.sequence_id}&prospect_id=${data?.prospect_id}`
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const prospectOpenedEmailLog = async (data) => {
  try {
    return await axios.get(
      `${mailsnow_url}/sequence/prospect-email-opened-log?sequence_id=${data?.sequence_id}&prospect_id=${data?.prospect_id}`
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const prospectClickedEmailLog = async (data) => {
  try {
    return await axios.get(
      `${mailsnow_url}/sequence/prospect-email-clicked-log?sequence_id=${data?.sequence_id}&prospect_id=${data?.prospect_id}`
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const addSequenceMail = async (data) => {
  return await axios.post(`${mailsnow_url}/sequence/add-sequence-mail`, data);
};

export const updateSequenceDate = async (data) => {
  return await axios.put(
    `${mailsnow_url}/sequence/update-start-date-sequence/${data.sequence_id}`,
    data
  );
};

export const allCount = async (data) => {
  try {
    return await axios.get(
      `${mailsnow_url}/analytics/all-count/${data.sequence_id}`
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const emailReach = async (data) => {
  try {
    return await axios.get(
      `${mailsnow_url}/analytics/email-reach/${data.sequence_id}`
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const campaignReach = async (data) => {
  try {
    return await axios.get(
      `${mailsnow_url}/analytics/campaign-reach/${data.sequence_id}`
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const leadQuality = async (data) => {
  try {
    return await axios.get(
      `${mailsnow_url}/analytics/lead-quality/${data.sequence_id}`
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const emailHealth = async (data) => {
  try {
    return await axios.get(
      `${mailsnow_url}/analytics/email-health/${data.sequence_id}`
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const markReplay = async (prospect_id) => {
  return await axios.put(
    `${mailsnow_url}/sequence/mark-replied/${prospect_id}`
  );
};

export const markBounced = async (prospect_id) => {
  return await axios.put(
    `${mailsnow_url}/sequence/mark-bounced/${prospect_id}`
  );
};

export const removeProspects = async (propectIds, sequenceId) => {
  const body = {
    prospectIds: propectIds,
    sequenceId: sequenceId,
  };
  try {
    const response = await axios.put(
      `${mailsnow_url}/sequence/delete-prospect`,
      body
    );
    return response.data;
  } catch (error) {
    console.error("Error removing prospects:", error);
    throw error;
  }
};

export const markBulkReplied = async (docsId) => {
  const body = {
    docIds: docsId,
  };
  try {
    const response = await axios.put(
      `${mailsnow_url}/sequence/bulk-mark-replied`,
      body
    );
    return response.data;
  } catch (error) {
    console.error("Error marking reply:", error);
    throw error;
  }
};

export const updateStatus = async (docsId, status) => {
  const body = {
    docIds: docsId,
    status: status,
  };
  try {
    const response = await axios.put(
      `${mailsnow_url}/sequence/update-status`,
      body
    );
    return response.data;
  } catch (error) {
    console.error("Error updating status:", error);
    throw error;
  }
};

export const updateTags = async (docsId, tags) => {
  const body = {
    docIds: docsId,
    tags: tags,
  };
  try {
    const response = await axios.post(
      `${mailsnow_url}/sequence/add-romove-tag`,
      body
    );
    return response.data;
  } catch (error) {
    console.error("Error add or removing tags:", error);
    throw error;
  }
};

export const deleteSequenceMail = async (smtp_id, sequence_id) => {
  try {
    return await axios.delete(
      `${mailsnow_url}/sequence/sequence-mail/${smtp_id}/${sequence_id}`
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const sequenceBreakup = async (sequence_id, startDate, endDate) => {
  const response = await axios.get(
    `${mailsnow_url}/analytics/sequence-breakup/${sequence_id}`,
    {
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    }
  );
  return response.data;
};

export const deleteSchedule = async (data) => {
  return await axios.delete(`${mailsnow_url}/sequence/schedule/${data.doc_id}`);
};

export const safetySettingsUpdate = async (sequence_id, type, status) => {
  const body = {
    type: type,
    status: status,
  };
  try {
    const response = await axios.put(
      `${mailsnow_url}/sequence/update-safety-settings/${sequence_id}`,
      body
    );
    return response.data;
  } catch (error) {
    console.error("Error updating status:", error);
    throw error;
  }
};

export const deleteGlobalEmailAccounts = async (smtpIds) => {
  const body = {
    smtpIds: smtpIds,
  };
  try {
    const response = await axios.put(
      `${mailsnow_url}/sequence/delete-global-emails`,
      body
    );
    return response.data;
  } catch (error) {
    console.error("Error updating status:", error);
    throw error;
  }
};

export const setDefaultMailAccount = async (smtpIds) => {
  try {
    const response = await axios.put(
      `${mailsnow_url}/sequence/change-default-status/${smtpIds}`
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.message || "Failed to set default");
    } else {
      throw new Error(error.message || "Unknown error occurred");
    }
  }
};

export const checkCustomTrackingStatus = async (tracking_domain) => {
  const body = {
    tracking_domain,
  };
  try {
    const response = await axios.post(
      `${mailsnow_url}/sequence/custom-tracking-domain`,
      body
    );
    return response.data;
  } catch (error) {
    console.error("Error add or removing tags:", error);
    throw error;
  }
};

export const editSequenceName = async (sequence_id, sequence_name) => {
  const body = {
    sequence_name: sequence_name,
  };
  try {
    const response = await axios.put(
      `${mailsnow_url}/sequence/update-sequence-name/${sequence_id}`,
      body
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.message || "Failed to edit name");
    } else {
      throw new Error(error.message || "Unknown error occurred");
    }
  }
};

export const updateSequence = async (sequence_id, smtpIds) => {
  
  const body = {
    sequence_id: sequence_id,
    smtpIds: smtpIds,
  };
  try {
    console.log("body",body)
    const response = await axios.post(
      `${mailsnow_url}/sequence/add-sequence-mail`,
      body
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.message || "Failed to set default");
    } else {
      throw new Error(error.message || "Unknown error occurred");
    }
  }
};

export const leadStatus = async (sequence_id) => {
  const response = await axios.get(
    `${mailsnow_url}/analytics/lead-status/${sequence_id}`
  );
  return response.data;
};


export const updateUnsubscribe  = async (sequence_id, status,index,label) => {
  const body = {
    unsubscribe_status: status,
    unsubscribe_index: index,
    unsubscribtion_link:label
  };
  try {
    console.log("qowuexc",body)
    const response = await axios.put(
      `${mailsnow_url}/sequence/update-sequence-name/${sequence_id}`,
      body
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.message || "Failed to edit name");
    } else {
      throw new Error(error.message || "Unknown error occurred");
    }
  }
};

export const deleteSequence = async ( sequence_id) => {
  try {
    return await axios.delete(
      `${mailsnow_url}/sequence/delete-sequence/${sequence_id}`
    );
  } catch (error) {
    console.log("ddd", error);
    const errorMessage = error.response && error.response.data 
      ? error.response.data.message || "Failed to delete sequence"
      : error.message || "Unknown error occurred";
    throw new Error(errorMessage);
  }
};