import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBaseUrl } from "../Utils/OptionsUtils";

async function getMessageList(page = 1, limit = 10, id) {
  const url = `${process.env.REACT_APP_TEAM_CHAT_BASEURL}/api/v1/messages?convoId=${id}&page=${page}&limit=${limit}`;
  // const url = `${getBaseUrl()}/api/v1/messages?convoId=${id}&page=${page}&limit=${limit}`;

  const response = await axios.get(url);
  console.log("apiresponse", JSON.stringify(response.data));
  return response.data;
}

export const useGetMessages = (limit, id) => {
  return useInfiniteQuery({
    queryKey: ["getChatMessageListApi", id],
    queryFn: async ({ pageParam = 1 }) => {
      try {
        const data = await getMessageList(pageParam, limit, id);
        return data;
      } catch (error) {
        throw error;
      }
    },
    getNextPageParam: (lastPage) => {
      const { hasNextPage, page } = lastPage;

      return hasNextPage ? page + 1 : undefined;
    },
  });
};
