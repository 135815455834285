import {
  Box,
  IconButton,
  Modal,
  Tab,
  Tabs,
  ToggleButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AntSwitch from "../../../../../../../MarketingStudio/MailsNow/CampInfo/element/Antswitch";
import GoogleImg from "../../../../Images/google-logo.png";
import MicrosoftImg from "../../../../Images/microsoft-logo.webp";
import Connections from "./Connections";
import Confirmation from "./Confirmation";
import GeneralSettings from "./GeneralSettings";
import Sending from "./Sending";
import EmailSetup from "./EmailSetup";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { updateSmtp } from "../../../../../../../MarketingStudio/webservices/sequence";
import { SetBodyValue } from "../../../../../../../MarketingStudio/store/templateStore";
import TrackingDomain from "../tracking/TrackingDomain";
import Overview from "../overview/Overview";
import EditConnection from "./EditConnection";

const EmailProfile = ({
  openSetting,
  setOpenSetting,
  data,
  activeTab,
  setActiveTab,
}) => {
  // const [openSmtp, setOpenSmtp] = useState(false);
  const [connect, setConnect] = useState(true);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const queryClient = useQueryClient();
  const { body, SetBody } = SetBodyValue();

  useEffect(() => {
    setConnect(data.active);
  }, [data]);

  const mutation = useMutation({
    mutationFn: updateSmtp,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["globalEmailAccounts"] });
    },
    onError: (error) => {
      console.error("Error adding:", error.response.data);
    },
  });

  const handleConfirmation = (value) => (event) => {
    setConnect(event.target.checked);

    const data = {
      doc_id: value,
      active: event.target.checked,
    };
    mutation.mutate(data);
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const closePopUp = () => {
    setOpenSetting(false);
    setActiveTab("Overview");
    SetBody("");
  };

  return (
    <>
      <Modal
        open={openSetting}
        onClose={closePopUp}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <Box
          sx={{
            borderRadius: "4px",
            bgcolor: "background.paper",
            position: "absolute",
            height: "85%",
            width: "70%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px 20px",
              backgroundColor: "#F9FAFB",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                {data?.sender_name}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                  {data?.email_address}
                </Typography>
                <Box
                  sx={{
                    height: "20px",
                    backgroundColor: "#E5E7EB",
                    borderRadius: "5px",
                    fontSize: "12px",
                    width: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  SMTP
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                    {connect ? "Connected" : "DisConnected"}
                  </Typography>
                  <Tooltip title={connect ? "Disconnect" : "Connect"} arrow>
                    <AntSwitch
                      checked={connect}
                      inputProps={{ "aria-label": "ant design" }}
                      onClick={handleConfirmation(data._id)}
                    />
                  </Tooltip>
                </Box>
                {/* <ToggleButton
                  aria-label="right aligned"
                  style={{
                    height: "30px",
                    padding: ".4rem .5625rem",
                    color: "var(--button-color)",
                    borderColor: "var(--button-color)",
                  }}
                  onClick={() => setOpenSmtp(true)}
                >
                  Edit Email Settings
                </ToggleButton> */}
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "start" }}>
              <IconButton onClick={closePopUp}>
                <CloseOutlinedIcon
                  sx={{ width: "20px", height: "20px", cursor: "pointer" }}
                />
              </IconButton>
            </Box>
          </Box>
          <Tabs
            value={activeTab}
            onChange={handleChange}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons
            sx={{
              // color: "#000",
              borderBottom: "1px solid var(--light-border-color)",
            }}
          >
            <Tab
              sx={{ textTransform: "none" }}
              label="Overview"
              value="Overview"
            />
            <Tab
              sx={{ textTransform: "none" }}
              label="Email Settings"
              value="Email Settings"
            />
            <Tab
              sx={{ textTransform: "none" }}
              label="General Settings"
              value="General Settings"
            />
            <Tab
              sx={{ textTransform: "none" }}
              label="Warm Up"
              value="Warm Up"
            />
            <Tab
              sx={{ textTransform: "none" }}
              label="Email Setup Score"
              value="Email Setup Score"
            />
            {/* <Tab
              sx={{ textTransform: "none" }}
              label="Custom Tracking Domain"
              value="Custom Tracking Domain"
            /> */}
          </Tabs>
          <Box
            sx={{
              padding: "15px 10px",
              overflowY: "auto",
              height: "calc(100% - 113px)",
              backgroundColor: "#F9FAFB",
            }}
          >
            {activeTab === "Overview" && <Overview />}
            {activeTab === "Email Settings" && <EditConnection data={data} />}
            {activeTab === "General Settings" && (
              <GeneralSettings data={data} />
            )}
            {activeTab === "Warm Up" && <Sending data={data} />}
            {activeTab === "Email Setup Score" && <EmailSetup data={data} />}

            {/* {activeTab === "Custom Tracking Domain" && <TrackingDomain />} */}
          </Box>
        </Box>
      </Modal>
      {/* <Connections openSmtp={openSmtp} setOpenSmtp={setOpenSmtp} data={data} /> */}
      <Confirmation
        openConfirmation={openConfirmation}
        setOpenConfirmation={setOpenConfirmation}
        setConnect={setConnect}
      />
    </>
  );
};

export default EmailProfile;
