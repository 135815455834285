import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { InputAdornment, TextField, Typography } from "@mui/material";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import QuillEditor from "../../../component/Editor/QuillEditor";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createStep, addVariant } from "../../../webservices/sequence";
import { SetBodyValue } from "../../../store/templateStore";
import { stepId } from "../../../store/userStore";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation, useNavigate } from "react-router-dom";

export default function EmailForm({ fcloseFun, data }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { stepid, setStepId } = stepId();
  const [newVariantid, setNewVariantid] = React.useState("");
  const [newSequenceId, setNewSequenceId] = React.useState("");

  console.log("dataxxxxxxx", data);

  const headerstyle = {
    p: "2px 4px",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F1F1F1",
    color: "gray",
    height: "42px",
  };

  const mutation = useMutation({
    mutationFn: createStep,
    onSuccess: (data) => {
      queryClient.invalidateQueries("stepList");
      setLoading(false);
      fcloseFun();
    },
    onError: (error) => {
      console.log("xxxxxxx", error);
      setLoading(false);
      alert("Error adding sequence");
    },
  });

  const createVariant = useMutation({
    mutationFn: addVariant,
    onSuccess: (data) => {
      queryClient.invalidateQueries("stepList");
      setStepId(null);
      setLoading(false);
      fcloseFun();
    },
    onError: (error) => {
      setLoading(false);
      alert("Error adding sequence");
    },
  });

  const [mergetype, setMergetype] = React.useState([]);
  const [subject, setSubject] = React.useState([]);
  const [preheader, setPreheader] = React.useState([]);
  const [value, setValue] = React.useState(1);
  const { body, SetBody } = SetBodyValue();
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const sequence_id = location.pathname.split("/")[4];

  React.useEffect(() => {
    setSubject((sub) => [...sub, data?.subject || ""]);
  }, [data]);

  const subjectname = subject.join("");
  const preheadername = preheader.join("");

  const handleChangesub = (event) => {
    setSubject([event.target.value]);
  };

  const handleChangepre = (event) => {
    setPreheader([event.target.value]);
  };

  const handleChangeMerge1 = (event) => {
    // setMergetype(event.target.value);
    setSubject((sub) => [...sub, event.target.value]);
  };

  const handleChangeMerge2 = (event) => {
    // setMergetype(event.target.value);
    setPreheader((pre) => [...pre, event.target.value]);
  };

  const handleIncrement = () => {
    setValue(value + 1);
  };

  const handleDecrement = () => {
    if (value > 1) {
      setValue(value - 1);
    }
  };

  const handleChange = (event) => {
    const newValue = parseInt(event.target.value);
    if (!isNaN(newValue)) {
      setValue(newValue);
    }
  };

  const createStepFun = () => {
    setLoading(true);
    const stepdata = {
      sequence_id: sequence_id,
      day: value,
      same_thread: "false",
      subject: subjectname,
      preheader: preheadername,
      content: body,
    };
    const varinatdata = {
      sequence_id: sequence_id,
      step_id: stepid,
      same_thread: data?.same_thread ? true : false,
      subject: subjectname,
      preheader: preheadername,
      content: body,
      variant_id: data?.variant_id || "",
    };

    if (stepid) {
      createVariant.mutate(varinatdata);
    } else {
      mutation.mutate(stepdata);
    }
  };

  const [showHide, setShowHide] = React.useState("1");

  return (
    <div style={{ paddingRight: "20px" }}>
      <div className="EmailHeader">
        <Paper elevation={0} component="form" sx={headerstyle}>
          <p style={{ padding: "0 10px", fontSize: "14px" }}>Subject</p>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <InputBase
            className="inputbase"
            sx={{ ml: 1, flex: 1 }}
            onChange={handleChangesub}
            name="subject"
            value={subjectname}
            placeholder="Leave this empty to send as a followup step..."
          />
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
          ></IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={mergetype}
              onChange={handleChangeMerge1}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              style={{
                height: "35px",
                backgroundColor: "#e5e7eb",
                fontSize: "12px",
              }}
            >
              <MenuItem value="">Merge Type</MenuItem>
              <MenuItem value="{{FirstName}}">FirstName</MenuItem>
              <MenuItem value="{{LastName}}">LastName</MenuItem>
              <MenuItem value="{{SubjectName}}">SubjectName</MenuItem>
            </Select>
          </FormControl>
        </Paper>
      </div>
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: "2px",
          marginLeft: "8px",
        }}
      >
        <span>hh</span>
        <span
          style={{
            paddingLeft: ".375rem",
            color: "#6b7280",
            fontSize: ".75rem",
            fontWeight: "500",
            lineHeight: "1rem",
          }}
        >
          Send this email in same thread as follow-up
        </span>
      </div> */}
      {showHide === "1" ? (
        <div
          className="helper-text"
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px",
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              color: "var(--button-color)",
              marginLeft: "auto",
            }}
            className="helper-text-btn"
            onClick={(e) => setShowHide("0")}
          >
            Set Pre Header{" "}
          </Typography>
          <HelpOutlineIcon
            style={{
              fontSize: "20px",
              paddingLeft: "5px",
              color: "var(--button-color)",
            }}
          />
        </div>
      ) : (
        <div className="PreHeader" style={{ marginBottom: "15px" }}>
          <Paper elevation={0} component="form" sx={headerstyle}>
            <p style={{ padding: "0 10px", fontSize: "14px" }}>Pre-Header</p>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              onChange={handleChangepre}
              name="subject"
              value={preheadername}
              placeholder="Please enter pre-header...."
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
            ></IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={mergetype}
                onChange={handleChangeMerge2}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                style={{
                  height: "35px",
                  backgroundColor: "#e5e7eb",
                  fontSize: "12px",
                }}
              >
                <MenuItem value="">Merge Type</MenuItem>
                <MenuItem value="{{FirstName}}">FirstName</MenuItem>
                <MenuItem value="{{LastName}}">LastName</MenuItem>
                <MenuItem value="{{SubjectName}}">SubjectName</MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </div>
      )}
      <div style={{ paddingLeft: "15px", width: "100%" }}>
        <div
          style={{
            width: "100%",
            height: "50vh",
            border: "1px solid var(--light-border-color)",
          }}
        >
          <QuillEditor data={data?.content || ""} />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid var(--light-border-color)",
          marginLeft: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 20px 15px 30px",
            gap: "5px",
          }}
        >
          <Typography sx={{ fontSize: "14px" }}>Send After</Typography>
          <TextField
            size="small"
            sx={{ width: "105px", paddingLeft: "7px" }}
            value={value}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <RemoveIcon
                    onClick={handleDecrement}
                    sx={{ fontSize: "14px", cursor: "pointer" }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <AddIcon
                    onClick={handleIncrement}
                    sx={{ fontSize: "14px", cursor: "pointer" }}
                  />
                </InputAdornment>
              ),
              inputProps: { inputMode: "numeric", pattern: "[0-9]*" },
            }}
          />
          <Typography sx={{ paddingLeft: "7px", fontSize: "14px" }}>
            days from step 1
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "15px",
          }}
        >
          <LoadingButton
            variant="contained"
            sx={{
              width: "80px",
              textTransform: "none",
              backgroundColor: "var(--button-color)",
              "&:hover": { backgroundColor: "var(--button-color)" },
            }}
            onClick={createStepFun}
          >
            Save
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}
