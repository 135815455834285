import {
  Button,
  Checkbox,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import Emails from "./EmailAccount.module.scss";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AntSwitch from "../../../../../../../MarketingStudio/MailsNow/CampInfo/element/Antswitch";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Actions from "./Actions";
import { Box } from "@mui/system";
import AccountType from "./AccountType";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EmailProfile from "./EmailProfile";
import Empty from "../../../../Images/Image2.png";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  deleteGlobalEmailAccounts,
  globalEmailAccounts,
  updateSmtp,
} from "../../../../../../../MarketingStudio/webservices/sequence";
import AddAccountTab from "./AddAccountTab";
import { snackbarStore } from "../../../../../../../MarketingStudio/store/userStore";

const style = {
  height: "30px",
  bgcolor: "var(--button-color)",
  color: "#fff",
  ":hover": { backgroundColor: "var(--button-color)" },
};

const EmailAccounts = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  // const [openAccountType, setOpenAccountType] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [activeTab, setActiveTab] = useState("Overview");
  const [emailData, setEmailData] = useState("");
  const queryClient = useQueryClient();
  const { setSnackbarValue } = snackbarStore();
  const [openSmtp, setOpenSmtp] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [smtpIds, setSmtpIds] = useState(null);

  const emailAccounts = useQuery({
    queryKey: ["globalEmailAccounts"],
    queryFn: () => globalEmailAccounts(),
  });
  const handleTagOpen = (e, id) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSmtpIds(id);
  };

  const mutation = useMutation({
    mutationFn: updateSmtp,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["globalEmailAccounts"] });
    },
    onError: (error) => {
      console.error("Error adding:", error.response.data);
    },
  });

  const handleOpenSettings = (data) => (e) => {
    e.stopPropagation();
    setOpenSetting(true);
    setEmailData(data);
  };

  const handleChange = (value) => (event) => {
    const data = {
      doc_id: value,
      active: event.target.checked,
    };
    mutation.mutate(data);
  };
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allIds = emailAccounts.data.data.map((item) => item._id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  const handleSelectRow = (id) => {
    setSelectedIds((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };
  const disable = selectedIds.length < 1;

  const handleDelete = async () => {
    try {
      await deleteGlobalEmailAccounts(selectedIds);
      queryClient.invalidateQueries({ queryKey: ["globalEmailAccounts"] });
      setSnackbarValue({
        open: true,
        severity: "success",
        message: "Email Account(s) Deleted",
      });
      setSelectedIds([]);
    } catch (error) {
      console.error("Failed to Delete Email:", error);
      setSnackbarValue({
        open: true,
        severity: "error",
        message: error.message || "Failed to delete email account.",
      });
    }
  };

  return (
    <div className={Emails.container}>
      <div className={Emails.header}>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div className={Emails.search}>
            <SearchIcon
              style={{ marginLeft: ".25rem", marginRight: ".25rem" }}
              fontSize="small"
            />
            <input
              type="text"
              placeholder="Search"
              name=""
              id=""
              className={Emails.searchInput}
            />
          </div>
          <IconButton className={Emails.iconButton} disabled={disable}>
            <Tooltip title="Refresh Setup Score" arrow>
              <AutorenewIcon fontSize="small" />
            </Tooltip>
          </IconButton>
          <IconButton className={Emails.iconButton} disabled={disable}>
            <Tooltip title="Disconnect Email Accounts" arrow>
              <LinkOffIcon fontSize="small" />
            </Tooltip>
          </IconButton>
          <IconButton className={Emails.iconButton} disabled={disable}>
            <Tooltip title="Delete Email Accounts" arrow>
              <DeleteOutlineIcon fontSize="small" onClick={handleDelete} />
            </Tooltip>
          </IconButton>
        </div>
        <div>
          <Button
            variant="contained"
            sx={style}
            onClick={() => setOpenSmtp(true)}
          >
            Add Email Accounts
          </Button>
        </div>
      </div>
      {emailAccounts?.isLoading ? (
        "Loading..."
      ) : emailAccounts?.data?.data?.length > 0 ? (
        <div style={{ height: "calc(100% - 110px)" }}>
          <TableContainer sx={{ overflowY: "auto", height: "100%" }}>
            <Table
              size="small"
              sx={{ minWidth: 300 }}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead
                sx={{
                  borderBottom: "1px solid #E2E3E6",
                  height: "54px",
                  zIndex: 3,
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "14px",
                      fontWeight: "600",
                      backgroundColor: "var(--light-bg-img-color)",
                      padding: "5px 10px",
                      width: "40px",
                    }}
                  >
                    <Checkbox
                      size="small"
                      onChange={handleSelectAll}
                      checked={
                        selectedIds.length === emailAccounts.data.data.length
                      }
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "13px",
                      fontWeight: "600",
                      backgroundColor: "var(--light-bg-img-color)",
                      padding: "5px 10px",
                    }}
                  >
                    Sender Name
                  </TableCell>

                  <TableCell
                    sx={{
                      fontSize: "13px",
                      fontWeight: "600",
                      padding: "5px 10px",
                      backgroundColor: "var(--light-bg-img-color)",
                      width: "120px",
                    }}
                  >
                    Setup Score
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "13px",
                      fontWeight: "600",
                      backgroundColor: "var(--light-bg-img-color)",
                      padding: "5px 10px",
                      width: "150px",
                    }}
                  >
                    Active Sequences
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "13px",
                      fontWeight: "600",
                      backgroundColor: "var(--light-bg-img-color)",
                      padding: "5px 10px",
                    }}
                  >
                    Email Sent
                  </TableCell>

                  <TableCell
                    sx={{
                      fontSize: "13px",
                      fontWeight: "600",
                      backgroundColor: "var(--light-bg-img-color)",
                      padding: "5px 20px 5px 10px",
                      width: "70px",
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ cursor: "pointer" }}>
                {emailAccounts?.data?.data?.map((item) => (
                  <TableRow sx={{ cursor: "pointer" }}>
                    <TableCell sx={{ padding: "5px 10px", width: "40px" }}>
                      <Checkbox
                        size="small"
                        checked={selectedIds.includes(item._id)}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSelectRow(item._id);
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ padding: "5px 10px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <AntSwitch
                          checked={item.active}
                          onChange={handleChange(item._id)}
                        />
                        <div
                          style={{
                            marginLeft: "8px",
                            cursor: "pointer",
                          }}
                          onClick={handleOpenSettings(item)}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: "600",
                                lineHeight: "1.25rem",
                                color: "#1f2937",
                                fontSize: "14px",
                              }}
                            >
                              {item?.sender_name}
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                borderRadius: "10px",
                                backgroundColor: "#E5E7EB",
                                padding: "3px 7px",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ fontSize: "10px" }}>SMTP</p>
                            </div>
                            {item.default && (
                              <div
                                style={{
                                  display: "flex",
                                  borderRadius: "10px",
                                  backgroundColor: "#D1FAE5",
                                  padding: "3px 7px",
                                  alignItems: "center",
                                }}
                              >
                                <p style={{ fontSize: "10px" }}>Default</p>
                              </div>
                            )}
                          </div>

                          <div
                            style={{
                              marginTop: ".25rem",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <p
                              style={{
                                fontWeight: "500",
                                fontSize: ".75rem",
                                lineHeight: "1.25rem",
                                textAlign: "center",
                                color: "#6b7280",
                                textTransform: "capitalize",
                              }}
                            >
                              {item?.email_address}
                            </p>
                            <CheckCircleOutlineIcon
                              fontSize="small"
                              sx={{
                                fontSize: "14px",
                                color: "green",
                                marginLeft: "7px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell sx={{ padding: "5px 10px" }}>
                      <div
                        style={{
                          border: "1px solid #F59E0B",
                          borderRadius: "15px",
                          padding: "5px 10px",
                          width: "50%",
                        }}
                      >
                        <p style={{ fontSize: "14px", color: "#F59E0B" }}>
                          {item?.score || 0}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell sx={{ padding: "5px 10px" }}>
                      {item?.activeSequencesCount}
                    </TableCell>
                    <TableCell
                      sx={{ cursor: "pointer", padding: "5px 0px" }}
                      align="left"
                    >
                      <Tooltip
                        title={
                          <>
                            Daily sending limit: {item?.daily_limit} <br />
                            Used today: {item?.sent_today} <br />
                            Remaining: {item?.daily_limit - item?.sent_today}
                          </>
                        }
                        arrow
                      >
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {/* <Slider
                          size="small"
                          aria-label="Small"
                          sx={{ width: "50px" }}
                        /> */}
                          <LinearProgress
                            variant="determinate"
                            value={item?.sent_today}
                            sx={{ width: "50px", borderRadius: "5px" }}
                          />
                          <Typography
                            sx={{ fontSize: "12px", marginLeft: "7px" }}
                          >
                            {item?.sent_today}/{item?.daily_limit}
                          </Typography>
                        </Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        padding: "5px 10px",
                      }}
                    >
                      <IconButton
                        onClick={(e) => {
                          handleTagOpen(e, item._id);
                          setEmailData(item);
                        }}
                      >
                        <MoreVertIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            height: "calc(100% - 110px)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Empty}
            alt="No Data Found"
            style={{ height: "200px", width: "200px" }}
          />
        </div>
      )}
      {/* <AccountType
        openAccountType={openAccountType}
        setOpenAccountType={setOpenAccountType}
      /> */}
      <AddAccountTab
        openSmtp={openSmtp}
        setOpenSmtp={setOpenSmtp}
        data={emailData}
      />
      <EmailProfile
        openSetting={openSetting}
        setOpenSetting={setOpenSetting}
        data={emailData}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <Actions
        anchorEl={anchorEl}
        openSetting={openSetting}
        setAnchorEl={setAnchorEl}
        setSelectedIds={setSelectedIds}
        smtpIds={smtpIds}
        setOpenSetting={setOpenSetting}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={emailData}
      />
    </div>
  );
};

export default EmailAccounts;
